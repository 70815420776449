<script>
export default {
  components: {
    hm_zoom: require('@/components/hm_zoom.vue').default,
    hm_slideshow: require('@/components/hm_slideshow.vue').default
  },
  metaInfo: {
    title: 'HoneyMoney.io — personal finance tool for savers', // 70 characters
    meta: [
      { name: 'description', content: 'HoneyMoney.io — personal finance tool for savers' }
    ]
  }
}
</script>

<template lang="pug">
#promo_en_index
  section.hero.is-light.is-success
    .hero-body: .container.content.has-text-centered
      h1.title.is-1 Be<span style='opacity: 0.5'>e</span> Saving with HoneyMoney
      h2.subtitle.is-4 Take safe, secure and total control over your finances
      //- h2.subtitle.is-6  You might <b>fall in love</b> with it!

  section.section.content
    .has-text-centered: img(src='@/assets/bees/with_incomes_expenses_eng.png', style='width: 100px;')
    .title.is-2.has-text-centered
      |  Tracking is Manual by Design*
    .subtitle.is-6.has-text-centered(style='margin-bottom: 0')
      | * No need to connect to your online-banking (but you can import your historical data from CSV though)
    .subtitle.is-4.has-text-centered
      | HoneyMoney increases your awareness about your money habits.<br>
      | Being fully aware of your money naturally changes how you spend it.

    .tile.is-ancestor
      .tile.is-parent.is-7: .tile.is-child.box
        .title.is-4 Calendar is the Best Way to View your Transactions
        ul
          li Plan your regular transactions and then only confirm them with actual amounts
          li Enter unplanned transactions with a simple click on a day
        hm_zoom: img(src='./assets/calendar.png', class='frame', v-img="true")
      .tile.is-parent
        .tile.is-child.box
          .title.is-4 Simplify Everything
          ul
            li No need to enter transactions individually, combine related
            li Enter amount and description at the same time
            li Categories will be used for reports and charts
          .has-text-centered
            hm_zoom: img(src='./assets/transactions_form.png', class='frame', v-img="true")

  section.section.content
    .has-text-centered: img(src='@/assets/bees/forecast_bee.png', style='height: 130px;')
    .title.is-2.has-text-centered
      |  Planning is Cash Flow Based, not Budget Based
    .subtitle.is-4.has-text-centered
      | You just plan your future incomes and expenses in the calendar.<br>
      | And HoneyMoney runs the cash flow simulation one year in the future.
    .tile.is-ancestor
      .tile.is-parent: .tile.is-child.box
        .title.is-4 Envelopes Give Your Money Meaning
        p You can track physical places where your money actually is (chequing/saving accounts, credit cards, investment accounts).
        p At the same time, know how much of your money is reserved for
        ul
          li planned expenses (Reserves),
          li unplanned expenses (HoneyMoney),
          li financial Goals and Funds.
        img(src='./assets/envelopes.png', class='frame')
      .tile.is-parent.is-7
        .tile.is-child.box
          .title.is-4 Cash Flow Forecasts
          p Get a prediction of how much more money needs to be reserved for expenses or can be safely invested. The system runs a simulation based on your planned expenses and transfers to Funds and Goals.
          hm_zoom.has-text-centered: img(src='./assets/forecast.png', class='frame', v-img="true")

  //- section.section: .container: .columns.is-centered
  //-   .column.is-narrow
  //-     .message.is-warning: .message-body.has-text-centered
  //-       .columns.is-vcentered.is-mobile.is-gapless
  //-         .column.is-narrow
  //-           div: img(src='@/assets/bees/thinking.png', style='height: 90px; transform: scaleX(-1);')
  //-           i wondering bee
  //-         .column: .is-size-2 Tell me more?

  section.section.content
    .has-text-centered: img(src='@/assets/bees/cool.png', style='height: 100px;')
    .title.is-2.has-text-centered
      |  Reports are OFF THE CHARTS
    .subtitle.is-4.has-text-centered
      | Carefully crafted to give you a lot, but not too much
    .tile.is-ancestor
      .tile.is-parent.is-vertical
        .tile.is-child.box
          .title.is-4 Compare Periods
          .has-text-centered
            hm_zoom: img(src='./assets/reports.png', class='frame', v-img="true", style='max-height: 300px;')
            p: i click to enlarge
        .tile.is-child.box
          .title.is-4 Predict Future Account Balances
          hm_zoom.has-text-centered: img(src='./assets/reports_future_balance.png', class='frame', v-img="true")
      .tile.is-parent.is-vertical
        .tile.is-child.box
          .title.is-4 See Expenses by Categories
          hm_zoom.has-text-centered: img(src='./assets/reports_categories.png', class='frame', v-img="true", style='height: 400px;')
        .tile.is-child.box
          .title.is-4 Track Your Net Worth
          hm_zoom.has-text-centered: img(src='./assets/reports_net_worth.png', class='frame', v-img="true")

  //- section.section
  //-   .container: .columns
  //-     .column
  //-     .column.is-narrow
  //-       .message.is-success: .message-body.has-text-centered
  //-         .columns.is-vcentered.is-mobile.is-gapless
  //-           .column.is-narrow
  //-             div: img(src='@/assets/bees/interested.png', style='height: 110px;')
  //-             i curious bee
  //-           .column: .is-size-1 MORE!
  //-     .column

  section.section.content
    .has-text-centered: img(src='@/assets/bees/piggy_bank.png', style='height: 120px;')
    .title.is-2.has-text-centered
      |  You might fall in love with HoneyMoney
    //- .subtitle.is-4.has-text-centered
    //-   | Carefully crafted to give you a lot, but not too much
    .tile.is-ancestor
      .tile.is-parent: .tile.is-child.box
        .title.is-4 Supercharge Your Savings
        p HoneyMoney is not about escaping from living "Paycheck-To-Paycheck". Although it can help with that too. HoneyMoney is the only tool specifically designed to help people save more money.
        p Improve your "Financial Badassity™":
        ul
          li Track your <b>Savings Rate</b> — the most important metric
          li Track progress towards your financial goals
          li Track and analyze your spending
        .has-text-centered: img(src='@/assets/bees/with_honey.png', style='width: 130px;')

      .tile.is-parent: .tile.is-child.box
        .title.is-4 Make Good Habits
        p Just recording a number is not difficult, requires no new skills, and takes only 20 minutes a week. Tracking all your expenses is just a really good habit, that nobody taught us when we were kids. It is as simple as brushing your teeth.
        p You don't need any budgets or restrictions, <b>you're free to buy whatever you want</b> as long as you track it!
        p You can also start with simple tracking, planning will come later.
        .has-text-centered
          img(src='@/assets/bees/on_toilet.png', style='width: 130px;')
          br
          small Don't force it

      .tile.is-parent.is-vertical
        .tile.is-child.box
          .title.is-4 Keep it in your pocket
          p You can enter transactions on the go with the mobile web-app (PWA):
          p.has-text-centered
            .columns.is-gapless.is-mobile
              //- https://app.shotbot.io/#/project
              .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/list.png')
              .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/form.png')
              .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/breakdown.png')
        .tile.is-child.box
          .title.is-4 And...
          ul
            li Use 172 currencies!
            li Support an independent developer!!
            li Bees are cool no matter what!!!

  section.section.content
    .title.is-2.has-text-centered HoneyMoney Works Since 2011
    .tile.is-ancestor
      // .tile.is-parent: .tile.is-child.box
      //   .title.is-4 Human Support
      //   p Have questions about the app or just personal finance in general, drop me a line at <a href='mailto:HoneyMoney@ildar.ca'>HoneyMoney@ildar.ca</a>.
      //   p I answer all emails personally.
      //   .has-text-centered: img(src='@/assets/images/about/about_support.png', size: '100x100')
      //   p: i Ildar Abdulin<br>founder of HoneyMoney

      .tile.is-parent: .tile.is-child.box
        .columns
          .column
            .title.is-4 About HoneyMoney
            .columns
              .column.is-narrow
                hm_slideshow(style="width: 150px; height: 190px")
                  div: img(src='@/assets/me1.png', style='width: 150px;')
                  div(style='display: none'): img(src='@/assets/me2.png', style='width: 150px;')
              .column
                p Hello, my name is <a href='http://www.ildar.ca' target='_blank'>Ildar</a> 👋, I live in Edmonton, Alberta, Canada 🇨🇦.
                p I'm originally from Russia, that's why the initial version of HoneyMoney was launched in <a href="https://hmbee.ru/" target='_blank'>Russian</a> back in 2011.
                p Now is the time to bring HoneyMoney to the English world as well.
                p I made HoneyMoney because I didn't like any other options on the market. I tailored it to my needs, and with time it became a small, but profitable business. I've been running it for 10 (!) years now.

                p I'm the very first user of HoneyMoney, but there're also hundreds of other happy users. This means a lot to me.

                p HoneyMoney is for personal finance geeks, savers, <abbr title='Financial Independence, Retire Early'>FIRE</abbr>-achievers, people who want to control their finances.

                p And all the people who want to become ones.
          .column.is-narrow
            .title.is-4 People Love HoneyMoney
            p <strong>100 testimonials</strong> in one word cloud:
            hm_zoom.has-text-centered: img(src='./assets/wordcloud.png', class='frame', v-img="true", style='height: 200px')

  section.hero.is-light.is-bold: .hero-body: .container.content.has-text-centered
    .title.is-2 Be<span style='opacity: 0.3'>e</span> good with money
    .subtitle.is-4 It's easy, and it works
    a.button.is-info.is-rounded(:href="HM.demo_url") 👀 View Demo
    span.is-size-4 &nbsp;&nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;&nbsp;
    a.button.is-success.is-rounded(:href="HM.app_url + '/app/sign_up'") ✔ Get Started
    .has-text-right: router-link.button.is-text(to="/en/why") Why HoneyMoney Works »
</template>

<style lang='sass'>

</style>
